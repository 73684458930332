import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";


const Login = () => import(/* webpackChunkName: "login" */"@/pages/Login.vue");

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
const Integrations = () => import(/* webpackChunkName: "common" */ "@/pages/Integrations.vue");
const Organizations = () => import(/* webpackChunkName: "common" */ "@/pages/Organizations.vue");
const OrganizationCard = () => import(/* webpackChunkName: "common" */ "@/pages/OrganizationCard.vue");
const IntegrationCard = () => import(/* webpackChunkName: "common" */ "@/pages/IntegrationCard.vue");
const Users = () => import(/* webpackChunkName: "common" */ "@/pages/Users.vue");
const UserCard = () => import(/* webpackChunkName: "common" */ "@/pages/UserCard.vue");
const BaseProductCard = () => import(/* webpackChunkName: "common" */ "@/pages/BaseProductCard.vue");
const BaseProducts = () => import(/* webpackChunkName: "common" */ "@/pages/BaseProducts.vue");
const StoreCard = () => import(/* webpackChunkName: "common" */ "@/pages/StoreCard.vue");
const Stores = () => import(/* webpackChunkName: "common" */ "@/pages/Stores.vue");
const FinanceGroupCard = () => import(/* webpackChunkName: "common" */ "@/pages/FinanceGroupCard.vue");
const FinanceGroups = () => import(/* webpackChunkName: "common" */ "@/pages/FinanceGroups.vue");
const StoreIntegrationCard = () => import(/* webpackChunkName: "common" */ "@/pages/StoreIntegrationCard.vue");
const StoreIntegrations = () => import(/* webpackChunkName: "common" */ "@/pages/StoreIntegrations.vue");


const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
          requiresAuth: false,
        },
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/integrations",
    children: [
      {
        path: "organizations",
        name: "organizations",
        component: Organizations
      },
      {
        path: "organization-create",
        name: "organization-create",
        component: OrganizationCard
      },
      {
        path: "organization-edit/:id",
        name: "organization-edit",
        component: OrganizationCard,
        props: true
      },
      {
        path: "integrations",
        name: "integrations",
        component: Integrations
      },
      {
        path: "integration-create",
        name: "integration-create",
        component: IntegrationCard
      },
      {
        path: "integration-edit/:id",
        name: "integration-edit",
        component: IntegrationCard,
        props: true
      },
      {
        path: "users",
        name: "users",
        component: Users
      },
      {
        path: "user-create",
        name: "user-create",
        component: UserCard
      },
      {
        path: "user-edit/:id",
        name: "user-edit",
        component: UserCard,
        props: true
      },
      {
        path: "base-products",
        name: "base-products",
        component: BaseProducts
      },
      {
        path: "base-product-create",
        name: "base-product-create",
        component: BaseProductCard
      },
      {
        path: "base-product-edit/:id",
        name: "base-product-edit",
        component: BaseProductCard,
        props: true
      },
      {
        path: "stores",
        name: "stores",
        component: Stores
      },
      {
        path: "store-create",
        name: "store-create",
        component: StoreCard
      },
      {
        path: "store-edit/:id",
        name: "store-edit",
        component: StoreCard,
        props: true
      },
      {
        path: "finance-groups",
        name: "finance-groups",
        component: FinanceGroups
      },
      {
        path: "finance-group-create",
        name: "finance-group-create",
        component: FinanceGroupCard
      },
      {
        path: "finance-group-edit/:id",
        name: "finance-group-edit",
        component: FinanceGroupCard,
        props: true
      },
      {
        path: "store-integrations",
        name: "store-integrations",
        component: StoreIntegrations
      },
      {
        path: "store-integration-create",
        name: "store-integration-create",
        component: StoreIntegrationCard
      },
      {
        path: "store-integration-edit/:id",
        name: "store-integration-edit",
        component: StoreIntegrationCard,
        props: true
      },
    ]
  },
  { path: "*", name: "not-found", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
